import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import Layout from '../components/layout';

const mapStateToProps = ({ data }) => {
  return { data };
};

const Apply = ({ data }) => (
  <Layout showFullNav={false} showFooter={false}>
    <Helmet
      title="Apply for a loan"
      meta={[
        { name: 'description', content: 'Apply for a loan' },
        { name: 'robots', content: 'noindex, nofollow' },
      ]}

    >
      <html lang="en" />
      <script type="text/javascript">{`
      window.clearChoice_conf = window.clearChoice_conf || {};
      clearChoice_conf = {
        key: 'EBAA85DE-BBA9-4190-8C4D-11DAC46B7948', 
        ref: '174p',
        elemId: 'ccApply', 
        submitText: "Get my quote",  
        loanAmount: ${data.loanAmount}, 
        loanTerm: ${data.loanTerm},
        laMax: 10000, 
        laMin: 100,
        ltMax: 60,
        ltMin: 3, 
        ltStep: 1, 
        fixedControls: true, 
      };
    `}</script>
    <script type="text/javascript" src="https://3pi.choosewisely.co.uk/ccLoader.js"></script>
    </Helmet>
    <Container className="application-form-container">
      <Row>
        <Col xl={{ size: 6, offset: 3 }} lg={{ size: 8, offset: 2 }}>
          <div id="ccApply"></div>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default connect(mapStateToProps)(Apply);
