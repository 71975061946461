import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';

import { Container, Row, Col, Nav } from 'reactstrap';

const FooterNavigation = ({ navdata }) => (
  <StaticQuery
    query={graphql`
      query FooterNavQuery {
        allContentfulPage(filter: { node_locale: { regex: "/en-US/" } }) {
          edges {
            node {
              id
              name
              slug
              navigationTitle
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Container>
          <Row>
            <Col>
              <Nav className="footerNavigation text-center">
                {data.allContentfulPage.edges.map(({ node }) => {
                  return (
                    <Link key={node.id} className="nav-link" to={node.slug}>
                      {node.navigationTitle}
                    </Link>
                  );
                })}
              </Nav>
            </Col>
          </Row>
        </Container>
      );
    }}
  />
);

export default FooterNavigation;
