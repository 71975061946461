import React from 'react';
import PropTypes from 'prop-types';
import Navigation from './navigation';
import FooterNavigation from './footerNavigation';
import Legal from './legal';
import Cookie from './cookie';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'rc-slider/assets/index.css';
import './../styles/theme.scss';

const Layout = ({ children, showFullNav = true, showFooter = true }) => (
  <React.Fragment>
    <Navigation showFullNav={showFullNav} />
    {children}
    {showFooter && (
      <React.Fragment>
        <footer className="siteFooter">
          <FooterNavigation />
          <Legal />
        </footer>
      </React.Fragment>
    )}
    <Cookie />
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
